import cn from 'classnames';
import { GetStaticProps } from 'next';
import { composeProps } from 'next-compose-props';
import { useSelector } from 'react-redux';

import type { NextPageWithLayout } from '@monorepo/types';

import { ErrorPage } from '@common/components';
import { ErrorLayout } from '@common/layouts';
import { selectTheme, wrapper } from '@common/store';
import { getTranslations } from '@common/utils';
import { WebLayout } from '@marketing/layouts';

const Custom404: NextPageWithLayout = () => {
	const theme = useSelector(selectTheme);
	const Layout = theme === 'maia' ? WebLayout : ErrorLayout;

	return (
		<Layout>
			<ErrorPage error={404} className={cn(theme === 'maia' && 'bg-primary50')} />
		</Layout>
	);
};

export const getStaticProps = wrapper.getStaticProps(() => {
	return composeProps(getTranslations) as GetStaticProps;
});

export default Custom404;
